import $ from 'jquery'
import 'jquery-migrate'
import jqueryBridget from 'jquery-bridget'
import Masonry from 'masonry-layout'
import 'picturefill'
import 'screenfull'

import './classList'
import './iframeResizer'
import './jquery.bxslider'
import './jquery.cookie'
import './jquery.easytabs.min'
import './jquery.event.move'
import './jquery.focuspoint'
import './jquery.hashchange'
import './jquery.twentytwenty'
import './jquery.validate'
import './jquery.validate.unobtrusive'
import './jquery.visible.min'
import './modernizr.custom'
import './ScrollMagic'

jqueryBridget('masonry', Masonry, $)
